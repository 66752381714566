import { BrowserRouter, Routes, Route } from 'react-router-dom';
import React, {Suspense} from 'react';
import ProgressBar from "react-topbar-progress-indicator";
import LoggedInRoute from './RoutesHandler.js';

// import 'jquery/src/jquery';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.min.js';

const TestAttempt = React.lazy(() => import('../pages/TestAttempt'))
const TestResult = React.lazy(() => import('../pages/TestResult'))
const Login = React.lazy(() => import('../pages/Login'))
const BlankLogin = React.lazy(() => import('../pages/BlankLogin'))
const Logout = React.lazy(() => import('../pages/Logout'))
const Home = React.lazy(() => import('../pages/Home'))
const Dashboard = React.lazy(() => import('../pages/Dashboard'))
const LoggedInHome = React.lazy(() => import('../pages/LoggedInHome'));
const MyPackage = React.lazy(() => import('../pages/MyPackage'));
const ShowPackages = React.lazy(() => import('../pages/ShowPackages'));
const MyTests = React.lazy(() => import('../pages/MyTests'));
const Profile = React.lazy(() => import('../pages/Profile'));
const WordofTheDay = React.lazy(() => import('../pages/WordofTheDay'));
const SavedWords = React.lazy(() => import('../pages/SavedWords'));
const Quizzes = React.lazy(() => import('../pages/Quizzes'));
const Feedback = React.lazy(() => import('../pages/Feedback'));
const Tests = React.lazy(() => import('../pages/Tests'))
const ExamGroup = React.lazy(() => import('../pages/ExamGroup'))
const ExamList = React.lazy(() => import('../pages/ExamList'))
const OtherExams = React.lazy(() => import('../pages/OtherExams'))
const AllTestSeries = React.lazy(() => import('../pages/AllTestSeries'))
const ExamCalender = React.lazy(() => import('../pages/ExamCalender'))
const TermAndConditions = React.lazy(() => import('../pages/TermAndConditions'))
const GeneralKnowledgeAndCurrentAffairs = React.lazy(() => import('../pages/GeneralKnowledgeAndCurrentAffairs'))
const BuyNow = React.lazy(() => import('../pages/BuyNow'))
const PaymentSuccessPage = React.lazy(() => import('../pages/PaymentSuccessPage'))
const PaymentFailedPage = React.lazy(() => import('../pages/PaymentFailedPage'))
const AboutUs = React.lazy(() => import('../pages/AboutUs'))

function OurRoutes() {
    return (
        <BrowserRouter>
            <Suspense fallback={<ProgressBar />}>
                <Routes>
                    <Route exact path="/test/:id" element={<TestAttempt title="Test Attempt"/>} />
                    <Route exact path="/report/:id" element={<TestResult title="Test Result"/>} />
                    <Route exact path="/login" element={<Login title="Login"/>} />
                    <Route exact path="/terms-and-conditions" element={<TermAndConditions title="Terms and Condition" />} />
                    <Route exact path="/login-main" element={<BlankLogin title="Login"/>} />
                    <Route exact path="/about-us" element={<AboutUs title="About Us"/>} />
                    <Route exact path="/logout" element={<Logout title="Logout"/>} />
                    <Route exact path="/payment-success-page" element={<PaymentSuccessPage title="Payment Success"/>} />
                    <Route exact path="/payment-failed-page" element={<PaymentFailedPage title="Payment Failed"/>} />
                    <Route exact path="/" element={<LoggedInRoute isLoggedOutRoute={true}/>}>
                        <Route exact path="/" element={<Home title="The Exam Planet"/>} />
                    </Route>
                    <Route exact path="/dashboard" element={<LoggedInRoute />}>
                        <Route exact path="/dashboard" element={<Dashboard children={<LoggedInHome />} title="Dashboard" />} />
                    </Route>
                    <Route exact path="/my-tests" element={<LoggedInRoute />}>
                        <Route exact path="/my-tests" element={<Dashboard children={<MyTests />} title="My Tests" />} />
                    </Route>
                    <Route exact path="/my-packages" element={<LoggedInRoute/>}>
                        <Route exact path ="/my-packages" element={<Dashboard children={<MyPackage/>} title="My Package"/>}/>
                    </Route>
                    <Route exact path="/show-packages" element={<LoggedInRoute/>}>
                        <Route exact path="/show-packages" element={<Dashboard children={<ShowPackages/>} title="Buy Packages"/>}/>
                    </Route>
                    <Route exact path="/profile" element={<LoggedInRoute/>}>
                        <Route exact path ="/profile" element={<Dashboard children={<Profile/>} title="My Profile"/>}/>
                    </Route>
                    <Route exact path="/dictionary/word-of-the-day" element={<LoggedInRoute/>}>
                        <Route exact path="/dictionary/word-of-the-day" element={<Dashboard children={<WordofTheDay/>} title="Word of the day"/>}/>
                    </Route>
                    <Route exact path="/dictionary/saved-words" element={<LoggedInRoute/>}>
                        <Route exact path="/dictionary/saved-words" element={<Dashboard children={<SavedWords/>} title="Saved Words"/>}/>
                    </Route>
                    <Route exact path="/quizzes" element={<LoggedInRoute/>}>
                        <Route exact path="/quizzes" element={<Dashboard children={<Quizzes/>} title="My Quizzes"/>}/>
                    </Route>
                    <Route exact path="/Feedback" element={<LoggedInRoute/>}>
                        <Route exact path="/Feedback" element={<Dashboard children={<Feedback/>} title="Feedbacks"/>}/>
                    </Route>
                    <Route exact path="/buy-now/:id" element={<LoggedInRoute/>}>
                        <Route exact path="/buy-now/:id" element={<Dashboard children={<BuyNow/>} title="Buy Package"/>}/>
                    </Route>
                    <Route exact path="/" element={<Home title="The Exam Planet"/>} />
                    <Route exact path="/group/:examslug" element={<ExamGroup title="Exam Group"/>} />
                    <Route exact path="/exams/:examslug" element={<ExamList title="Exams Lists"/>} />
                    <Route exact path="/tests/:examslug" element={<Tests title="Tests"/>} />
                    <Route exact path="/otherExams/:examslug" element={<OtherExams title="Other Exams"/>} />
                    <Route exact path="/all-testseries/" element={<AllTestSeries title="AllTestSeries"/>} />
                    <Route exact path="/exam-calender/" element={<ExamCalender title="Exam Calender : Coming Soon"/>} />
                    <Route exact path="/general-knowledge-and-current-affairs/" element={<GeneralKnowledgeAndCurrentAffairs title="General Knowledge And Current Affairs : Coming Soon"/>} />
                    <Route exact path="/logout" element={<Logout title="Logout"/>} />
                    <Route exact path="/" element={<LoggedInRoute isLoggedOutRoute={true}/>}>
                        <Route exact path="/" element={<Home title="The Exam Planet"/>} />
                    </Route>
                    {/* <Route exact path="/report/:id" element={<LoggedInRoute />}>
                        <Route exact path="/report/:id" element={<TestResult title="Test Result"/>} />
                    </Route> */}
                    <Route exact path="/dashboard" element={<LoggedInRoute />}>
                        <Route exact path="/dashboard" element={<Dashboard children={<LoggedInHome />} title="Dashboard" />} />
                    </Route>
                    <Route exact path="/my-tests" element={<LoggedInRoute />}>
                        <Route exact path="/my-tests" element={<Dashboard children={<MyTests />} title="My Tests" />} />
                    </Route>
                </Routes>
            </Suspense>
        </BrowserRouter>
    );
}

export default OurRoutes
